// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap");

$bg-color-primary: #333333;
$bg-color-secondary: #f5d5df;
$bg-color-tertiary: #f7d5cb;

$text-color-primary: #ffffff;
$text-color-secondary: #3f549b;
$text-color-tertiary: #2c819a;

$highlight-color-primary: #ecb9be;
$highlight-color-secondary: #8cc5aa;

$font-stack: "Alegreya Sans SC", sans-serif;

html {
  font-family: $font-stack;
  color: $text-color-primary;
  background: $bg-color-primary;
  min-width: 300px;
  touch-action: manipulation;
  overflow-x: hidden;
  width: 100%;
}
