// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;700;900&display=swap");

//$bg-color-primary: #333333;
//$bg-color-secondary: #F5D5DF;
//$bg-color-tertiary: #F7D5CB;
//
//$text-color-primary: #FFFFFF;
//$text-color-secondary: #3F549B;
//$text-color-tertiary: #2C819A;
//
//$highlight-color-primary: #ECB9BE;
//$highlight-color-secondary: #8CC5AA;
//
//$font-stack: "Alegreya Sans SC", sans-serif;
//
//body {
//  font-family: $font-stack;
//  color: $text-color-primary;
//  background: $bg-color-primary
//}

$family-sans-serif: "Alegreya Sans SC", sans-serif;
$navbar-background-color: #333333;
$navbar-item-color: #ffffff;
$navbar-item-hover-color: #ecb9be;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: #ecb9be;
$navbar-dropdown-background-color: #333333;

$navbar-padding-horizontal: 10rem;
$navbar-padding-vertical: 10rem;
$navbar-item-img-max-height: 5rem;
$navbar-dropdown-arrow: #ffffff;
$navbar-dropdown-item-hover-color: #ecb9be;
$navbar-dropdown-item-hover-background-color: transparent;

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
//@import "../node_modules/bulma/sass/elements/button.sass";
//@import "../node_modules/bulma/sass/elements/container.sass";
//@import "../node_modules/bulma/sass/elements/title.sass";
//@import "../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/navbar.sass";
//@import "../node_modules/bulma/sass/layout/hero.sass";
//@import "../node_modules/bulma/sass/layout/section.sass";
